.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}

.formSect {
    margin: auto;
    max-width: 800px;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media only screen and (max-width: 650px) {
        padding-top: 20px;
    }
}

.questionType {
    color: #70757a;
    margin: 20px 40px;
}

.dashedLine {
    border-top: 1px dashed #70757a;
}

.optionText{
    width: '75%';
    font-family: 'Frank Ruhl Libre';
    word-break: break-word;
}
.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}
.other-ans-container{
    display: flex;
    flex-direction: row;
}

.question-image{
    width: 100%;
    height: auto;
    @media only screen and (max-width: 400px) {
        height: 100px;
    }
}

.questIndex_container{
    display: flex;
    .questRequired{
        color: #f9602c;
    }
}

.questionText{
    width: '100%';
    font-family: 'Frank Ruhl Libre';
    word-break: break-word;
}

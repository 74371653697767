#alert-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center;
    width: 100%;
}

.fullCont {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.successCont {
    border-top: 10px solid var(--theme);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.reviewRespondentFormReadonly {
    background-color: #fff;
    color: var(--gray) !important;
    padding: 10px 20px;
    letter-spacing: 0.45px;
    font-family: "Muli-Bold";
    
    .questionIndex {
        font-family: "Muli-Bold";
    }

    .questionTitle {
        margin-left: 10px;
        width: 100%;
        font-family: "Muli-Bold";
    }

    .starsWrapper {
        position: relative;
        height: 100%;
        
        .stars {
            margin-right: 10px;
        }

        .ratingDisplay {
            line-height: 40px;
        }
    }

    .options {
        width: 100%;
    }

    .textArea {
        width: 100%;
        .descriptionBox {
            width: 100%;
            margin-top: 15px;
            background-color: #f8fafc;
            font-family: "Muli-Bold";
            border-radius: 8px;
            color: #64748B;
            border: 1px solid #64748B;
        }

       
    }
}

.loaderCont {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    overflow-y: scroll;
}

.formSection {
    padding: 20px 0px;

    form {
        display: flex;
        flex-direction: column;
    
        button {
            align-self: center;
        }
    
        .reviewRespondentForm {
            background-color: #fff;
            color: var(--gray) !important;
            padding: 20px;
            letter-spacing: 0.45px;
            font-family: "Muli-Bold";
            
            .questionIndex {
                font-family: "Muli-Bold";
            }
        
            .questionTitle {
                margin-left: 10px;
                width: 100%;
                font-family: "Muli-Bold";
            }
        
            .starsWrapper {
                position: relative;
                height: 100%;
                
                .stars {
                    margin-right: 10px;
                }
        
                .ratingDisplay {
                    line-height: 40px;
                }
        
            }
        
            .textArea {
                width: 100%;
                .descriptionBox {
                    width: 100%;
                    margin-top: 15px;
                    background-color: #f8fafc;
                    font-family: "Muli-Bold";
                    border-radius: 8px;
                    color: #64748B;
                    border: 1px solid #64748B;
                }
        
                .descriptionBox::-webkit-input-placeholder { 
                    padding: 10px 0 0 10px;
                    font-family: "Muli-Bold";
                }
        
                .descriptionBox::-moz-placeholder { 
                    padding: 10px 0 0 10px;
                    font-family: "Muli-Bold";
                }
        
                .descriptionBox::-ms-input-placeholder { 
                    padding: 10px 0 0 10px;
                    font-family: "Muli-Bold";
                }
            }
        }
        
        .question, .options {
            width: 100% !important;
        }
        
        .submitButton span {
            font-family: "Muli-Bold" !important;
        }
        
        .submitButton {
            background-color: var(--maroon) !important;
            border-color: var(--maroon) !important;
            color: white !important;
            padding: 10px 28px !important;
          }
          
        .submitButton:disabled {
          background-color: #F8F9FB !important;
          border-color: #9DACB6 !important;
          color: var(--gray) !important;
          cursor: not-allowed !important;
        }
    }    
}


